:root {
    --color-blue: #08537c;
    --color-light-blue: #E3F5FC;
    --color-orange: #F27141;
    --color-light-orange: #FDF5F2;
    --color-grey: #F5F6F8;
    --color-red: #DF0606;
    --color-green: #2CAB38;
}

ul,
ol {
    list-style: none;
    margin: 0px !important;
    padding: 0px !important;
}

a {
    text-decoration: none;
}

button:focus {
    box-shadow: unset !important;
}

input:focus,
select:focus {
    outline: 0px;
    box-shadow: unset !important;
}

.after-none::after {
    display: none !important;
}

.before-none::before {
    display: none !important;
}

div .border-color-black {
    border-color: #000 !important;
}

div .border-color-blue {
    border-color: var(--color-blue) !important;
}

div .border-color-orange {
    border-color: var(--color-orange) !important;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-22 {
    font-size: 22px;
}

.fs-24 {
    font-size: 24px;
}

.color-blue {
    color: var(--color-blue) !important;
}

.bg-blue {
    background-color: var(--color-blue) !important;
}

.color-green {
    color: var(--color-green) !important;
}

.color-red {
    color: var(--color-red) !important;
}

.bg-red {
    background-color: var(--color-red) !important;
}

.color-grey {
    color: #848484 !important;
}

.bg-grey {
    background-color: var(--color-grey) !important;
}

.color-orange {
    color: var(--color-orange) !important;
}

.bg-orange {
    background-color: var(--color-orange) !important;
}

.color-white {
    color: #fff !important;
}

.bg-white {
    background-color: #fff !important;
}

.color-black {
    color: #000 !important;
}

.bg-black {
    background-color: #000 !important;
}

.color-light-blue {
    color: var(--color-light-blue) !important;
}

.bg-light-blue {
    background-color: var(--color-light-blue) !important;
}

.color-light-orange {
    color: var(--color-light-orange) !important;
}

.bg-light-orange {
    background-color: var(--color-light-orange) !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.cursor-pointer {
    cursor: pointer;
}

.buttonStyle1 {
    background-color: var(--color-orange);
    transition: 0.4s;
}

.buttonStyle1:hover {
    background-color: var(--color-blue);
}

.buttonStyle2 {
    background-color: transparent;
    border: 1px solid var(--color-red);
    color: var(--color-red);
    transition: 0.4s;
}

.buttonStyle2:hover {
    background-color: var(--color-red);
    color: #fff;
}

.buttonStyle3 {
    background-color: var(--color-green);
    transition: 0.4s;
    opacity: 0.8;
    border: 1px solid var(--color-green);
}

.buttonStyle3:hover {
    opacity: 1;
}

.buttonStyle4 {
    background: transparent linear-gradient(0deg, #12547C 0%, #2FA5EC 100%) 0% 0% no-repeat padding-box;
    transition: 0.3s;
}

.buttonStyle4:hover {
    box-shadow: 0px 6px 14px #0000003D;
}

.buttonStyle5 {
    background-color: #fff;
    color: var(--color-blue);
    border: 1px solid var(--color-blue);
    font-size: 14px;
    padding: 6px 20px;
    transition: 0.4s;
}

.buttonStyle5:hover {
    background-color: var(--color-blue);
    color: #fff;
}

.buttonStyle6 {
    background-color: var(--color-blue);
    transition: 0.4s;
    opacity: 0.8;
    color: #fff;
}

.buttonStyle1:hover {
    opacity: 1;
}

.z-index-9 {
    z-index: 9 !important;
}

.z-index-99 {
    z-index: 99 !important;
}