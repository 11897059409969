.FlightSearchForm h2 {
    color: rgb(10, 10, 10);
}

/* ----25-10-2023 custom css--- */
.cms-bg-primary{background-color: #0A4F9E;}
.cms-light-bg{background-color: #E3F5FC;}

.travelers-card{
    min-width: 420px;
    z-index: 2;
    height: 0px;
    overflow: hidden;
}
.travelers-card.show{
    min-height: 250px;
}
.airport-list {
    height: 0px;
    overflow: hidden;
}
.airport-list.show {
    height: 280px;
    overflow-y:scroll;
}
.flip-icon {
    cursor: pointer;
    transition: .5s;
}

.flip-icon.flip{
    transform: rotate(180deg);
}