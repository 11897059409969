// Here you can add other styles

:root {
    --main-grey-color: #f3f3f7;
    --red-color: #DF0606;
    --green-color: #2CAB38;
    --main-orange-color: #F27141;
    --light-orange-color: #FFE0D5;
    --main-light-blue-color: #E3F5FC;
    --main-light-pink-color: #FDE6EF;
    --main-light-purple-color: #EEEAFE;
    --main-dark-grey-color: #77809E;
    --main-blue-color: #37C1FD;
    --main-pink-color: #EB4586;
    --main-purple-color: #9077F8;
    --main-dark-blue-color: #003F8D;
}

button:focus,
input:focus,
textarea:focus {
    box-shadow: unset !important;
}

.bg-white {
    background-color: #fff;
}

.bg-grey {
    background-color: #F9FAFF;
}

.br-5 {
    border-radius: 5px;
}

.br-10 {
    border-radius: 10px;
}

body>iframe {
    display: none !important;
}

.sidebar-logo-menu button.btn {
    background-color: var(--main-grey-color);
}

.sidebar-logo-menu .dropdown-toggle::after {
    visibility: hidden;
}

.sidebar-logo-menu img {
    height: 20px;
    width: auto;
}

.sidebar button.sidebar-toggler {
    visibility: hidden;
}

.america-cards .text .h4 {
    font-size: 18px;
}

.america-cards .text .h1 {
    font-size: 40px;
}

.america-cards .main-btn button {
    padding: 8px 25px;
    font-size: 14px;
    background-color: #fff;
    transition: 0.4s;
}

.america-cards-a .main-btn button {
    color: var(--main-blue-color);
}

.america-cards-a .main-btn:hover button {
    background-color: var(--main-blue-color);
    color: #fff;
}

.america-cards-b .main-btn button {
    color: var(--main-pink-color);
}

.america-cards-b .main-btn:hover button {
    background-color: var(--main-pink-color);
    color: #fff;
}

.america-cards-c .main-btn button {
    color: var(--main-purple-color);
}

.america-cards-c .main-btn:hover button {
    background-color: var(--main-purple-color);
    color: #fff;
}

.america-cards-a {
    background-color: var(--main-light-blue-color);
}

.america-cards-b {
    background-color: var(--main-light-pink-color);
}

.america-cards-c {
    background-color: var(--main-light-purple-color);
}

.america-tabs .nav-item .nav-link {
    border: 1px solid #D4DCE5;
    color: #666F76;
    margin-right: 15px;
    font-size: 14px;
    padding: 8px 25px;
}

.america-tabs .nav-item .nav-link.active {
    background-color: var(--main-orange-color);
    color: #fff;
    border-color: var(--main-orange-color);
}

.america-tabs .user-profile img {
    width: 30px;
}

.america-tabs table thead {
    background-color: var(--main-light-purple-color);
}

.america-tabs table tr th,
.america-tabs table tr td {
    padding: 10px 20px;
    font-size: 13px;
    color: #1C1F21;
}

.america-tabs table tr td {
    color: #666F76;
}

.america-tabs table .delet {
    color: var(--red-color);
}

.create-btn {
    background-color: var(--main-dark-grey-color);
    color: #fff;
    font-size: 14px;
}

.table-search-box input:focus-visible {
    outline: 0px;
}

.table-search-box input {
    border-radius: 5px 0px 0px 5px;
    font-size: 14px;
}

.table-search-box button {
    border-radius: 0px 5px 5px 0px;
    top: 1px;
    position: relative;
}

.table-search-box input,
.table-search-box button {
    background-color: #EEF5FD;
    padding: 0px 15px;
    height: 40px;
    line-height: normal;
}

.back-btn {
    background-color: var(--main-orange-color);
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 30px;
    text-align: center;
    font-size: 22px;
}

.form-group .form-control {
    height: 45px;
    font-size: 14px;
}

.form-group label span {
    color: var(--red-color);
}

.form-group .upload-input[type="file"]::file-selector-button {
    height: 45px;
    background-color: #E9E9E9;
}

.form-box .dropdown.info button::after {
    visibility: hidden;
}

.form-box .dropdown.info button:hover,
.form-box .dropdown.info button:active,
.form-box .dropdown.info button:focus {
    background-color: transparent;
}

.form-box .form-check-input.blue {
    background-color: var(--main-dark-blue-color);
    opacity: 1;
}

.form-box .form-check-input.orange:checked {
    background-color: var(--main-orange-color);
    opacity: 1;
}

.orange-checked:checked {
    background-color: var(--main-orange-color);
    opacity: 1;
}

.form-box textarea {
    min-height: 100px !important;
}

// .form-box textarea.HTMLEditor {
//     min-height: 150px !important;
// }

.number {
    background-color: var(--main-grey-color);
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
}

.text-red {
    color: var(--red-color);
}

.create-btn.orange {
    background-color: var(--main-orange-color);
}

.dropdown.info button img {
    width: 18px;
    position: relative;
    top: -3px;
}

.yes-btn {
    background-color: #F05E5E;
    color: #fff;
    border-radius: 6px;
    padding: 5px 20px;
    font-size: 14px;
    border: 0px;
}

.cancel-btn {
    background-color: #fff;
    color: #333;
    border-radius: 6px;
    padding: 5px 20px;
    font-size: 14px;
    border: 1px solid #333;
}

.green-style1 {
    border-color: var(--green-color) !important;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    border-radius: 5px;
    transition: 0.4s;
    background-color: var(--green-color);
    opacity: 0.8;
}

.green-style1:hover {
    opacity: 1;
}

.red-style1 {
    border-color: var(--red-color) !important;
    color: var(--red-color);
    font-size: 14px;
    text-transform: capitalize;
    font-weight: bold;
    border-radius: 5px;
    transition: 0.4s;
}

.red-style1:hover {
    background-color: var(--red-color);
    color: #fff;

}

.cards {
    background-color: #f8f8f8;
}


// FlightSearchForm CSS START



// FlightSearchForm CSS END


.table-nowrap th,
.table-nowrap td {
    white-space: nowrap;
}

.passenger-count-btn span {
    border: 0px;
    height: 100%;
    width: 60%;
    left: 0px;
    position: relative !important;
    text-align: center;
    padding: 0;
    width: 100% !important;
    display: flex !important;
    height: 30px !important;
    border: 1px solid var(--main-color-green) !important;
    border-radius: 50px !important;
    overflow: hidden !important;
    background: #FFF;

}

.passenger-count-btn span input {
    display: flex !important;
    left: 0px !important;
    width: 70% !important;
    position: absolute !important;
    height: 100% !important;
    display: flex !important;
    left: 15% !important;
    border: none !important;
    color: var(--main-color-green);
    padding: 0px !important;
    text-align: center;
}

.passenger-count-btn span input:focus-visible {
    border: none;
    outline: none;
}

.passenger-count-btn span b:nth-child(odd):hover,
.passenger-count-btn span b:nth-child(even):hover {
    background: var(--main-color-green) !important;
}

.passenger-count-btn span b:nth-child(odd) {
    position: absolute;
    text-align: center;
    cursor: default;
    transition: all 0.1s ease 0s;
    box-shadow: rgba(0, 0, 0, 0.1) -1px -1px 3px inset, rgba(255, 255, 255, 0.7) 1px 1px 3px inset;
    background: rgba(0, 0, 0, 0.1);
    left: 0%;
    height: 100%;
    width: 30%;
    top: 0px;
    border: 0px;
    padding: 0px;
    margin: 0px;
    order: 1;
    background: red;
    left: 0px !important;
    width: 30% !important;
    position: absolute !important;
    height: 100% !important;
    display: flex !important;
    top: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
    cursor: pointer !important;
    background: var(--main-color-light-green) !important;
    transition: .5s !important;
}

.passenger-count-btn span b:nth-child(even) {
    border-color: rgba(0, 0, 0, 0.1);
    border-style: solid;
    text-align: center;
    cursor: default;
    transition: all 0.1s ease 0s;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: rgba(0, 0, 0, 0.1) -1px -1px 3px inset, rgba(255, 255, 255, 0.7) 1px 1px 3px inset;
    bottom: 2px;
    border-radius: 0px 0px 2px 2px;
    border-width: 0px 1px 1px;
    right: 0px !important;
    width: 30% !important;
    position: absolute !important;
    height: 100% !important;
    display: flex !important;
    top: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
    left: 70% !important;
    cursor: pointer !important;
    background: var(--main-color-light-green) !important;
    transition: .5s !important
}

.passenger-count-btn span b:nth-child(even)::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    box-shadow: 0px 3px 6px #00000029;
    background-image: url('https://assets.neofares.com/all-icon/plus.svg');
    background-size: 10px;
    background-position: center, center;
    background-repeat: no-repeat;
}

.passenger-count-btn span b:nth-child(odd)::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    box-shadow: 0px 3px 6px #00000029;
    background-image: url('https://assets.neofares.com/all-icon/minus.svg');
    background-size: 10px;
    background-position: center, center;
    background-repeat: no-repeat;
}

.passenger-count-btn .react-numeric-input b i {
    display: none;
}

.code-block {
    background-color: #f4f4f4;
    border: 1px solid #ccc;
    padding: 20px;
    font-family: monospace;
    white-space: pre;
    overflow-x: auto;
    border-radius: 5px;
}

.code-block code {
    display: block;
    color: #333;
    line-height: 1.4;
}

.ptr-from-card {
    z-index: 2;
}

.pointer-text {
    cursor: pointer;
}

.PricePicker {
    max-width: 80px;
}

.star-ratings {
    display: flex !important;
}

.star-ratings svg {
    width: 40px !important;
    height: 40px !important;
}

.star-ratings .star-container {
    margin: 0px 5px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.rti--tag {
    background-color: var(--light-orange-color) !important;
    border: 1px solid var(--main-orange-color) !important;
}

.card.active {
    border-color: var(--main-dark-blue-color) !important;
    background-color: var(--main-light-blue-color) !important;
}

// .card .fa-circle-check {
//     color: var(--main-dark-blue-color);
//     position: absolute;
//     right: -8px;
//     top: -8px;
//     font-size: 20px;
//     display: none;
// }

// .card.active .fa-circle-check{
//     display: block;
// }

.GoogleCrawl input {
    width: 15px;
    height: 15px;
}

.fileBox {
    cursor: pointer;
}

.fileBox img {
    width: 60px;
    height: 60px;
}

.imageGallery {
    max-height: 70vh;
    overflow: auto;
    padding-right: 15px;
    margin-top: 10px;
}

.imageGallery::-webkit-scrollbar {
    width: 5px;
}

.imageGallery::-webkit-scrollbar-track {
    background: #fff;
}

.imageGallery::-webkit-scrollbar-thumb {
    background: var(--main-dark-blue-color);
}

input[type="checkbox"].allow-crawl {
    cursor: pointer;
    width: 27px;
    height: 27px;
}

.container-lg {
    max-width: 100% !important;
}